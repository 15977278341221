export enum SsoType {
  LINKEDIN = "linkedin",
  GOOGLE = "google",
  OKTA = "okta",
  SAML = "saml",
}

export enum SsoUserAttribute {
  Title = "title",
  Gender = "gender",
  Country = "country",
  PhoneNumber = "phoneNumber",
}

export enum SsoAttributeDataType {
  Text = "Text",
  Number = "Number",
  Date = "Date",
  Raw = "Raw",
}

export enum IdentityScoreStatuses {
  GOOD = "good",
  WARNING = "warning",
  VERIFIED = "verified", // Only for OVERALL for now
  UNVERIFIED = "unverified", // Only for OVERALL for now
}

export enum IdentityScoreAttributes {
  OVERALL = "overall",
  TWO_OR_MORE_ADMINS = "two_admins",
  NO_INACTIVE_ADMINS = "no_inactive_admins",
  // ACTIVE_WEBSITE = "active_website",
  WEB_MATCHES_EMAIL_DOMAIN = "website_domain",
  VERIFIED_UPDATES = "verified_updates",
  VERIFIED_USERS = "verified_users",
  ALL_ACCOUNTS_VERIFIED = "all_email_verified",
  DUNS_NUM_MATCH = "duns_matches",
  TAX_ID_MATCH = "tax_id_matches",
  BANK_ACC_VERIFIED = "bank_verified",
  SSO = "sso",
  PROFILE_FROM_TRUSTED_ACCOUNT = "profile_from_trusted",
  NUM_OF_CONNECTIONS = "num_connections",
  THIRD_PARTY_VERIFIED = "third_party_verified",
  ACCOUNT_UPDATED = "account_updated",
  RECENT_CONNECTION = "recent_connection",
}

export enum EntityFeature {
  ADMINS_SEE_ALL_CONNECTIONS = "admins_see_all_connections",
  BULK_MESSAGE = "bulk_message",
  BADGES = "badges",
  INTERNAL_BADGES = "internal_badges",
  COMMUNITY_FORUM = "community_forum",
  DATA_WAREHOUSE = "data_warehouse",
  OVERRIDE_SCORES = "override_scores",
  OWN_PROFILE_WRITE_RESTRICTION = "own_profile_write_restriction",
  BUYER_INVITE = "buyer_invite",
  SELLER_INVITE = "seller_invite",
  DARK_BEAM = "dark_beam",
  DOW_JONES = "dow_jones",
  DUN_BRADSTREET = "dun_bradstreet",
  BUREAU_VAN_DJIK = "bureau_van_djik",
  ECO_VADIS = "eco_vadis",
  ADOBE_SIGN = "adobe_sign",
  DOCUSIGN = "docusign",
  PROVE_IDENTITY = "prove_identity",
  RAPID_RATINGS = "rapid_ratings",
  RBA = "rba",
  SECZETTA = "seczetta",
  SMART_CUBE = "smart_cube",
  VERIFF_IDENTITY = "veriff_identity",
  ZENDESK = "zendesk",
  REPORTS = "reports",
  RISKRECON = "riskrecon",
  TRANSFORMATION_EXPORT = "transformation_export",
  DISABLE_OFAC = "disable_ofac",
  ENABLE_ACTION_PLANS = "enable_action_plans",
  ENABLE_OPPORTUNITIES = "enable_opportunities",
  SUPPLIER_ONBOARDING_STATS = "supplier_onboarding_stats",
  CUSTOMER_ONBOARDING_STATS = "customer_onboarding_stats",
  TOPIC_REVIEW_STATS = "topic_review_stats",
  TIN_CHECK = "tin_check",
  GIACT = "giact",
  SAM_GOV = "sam_gov",
  BIT_SIGHT = "bit_sight",
  EXCLUDE_LEGACY_SUPPLIER_STATS = "exclude_legacy_supplier_stats",
  SLACK = "slack",
  WORKFLOW_BUILDER = "workflow_builder",
}

export enum EntityFeatureDescription {
  ADMINS_SEE_ALL_CONNECTIONS = "This makes it so that the company admins will see the last 20 updated connections on the home page. If this is not selected, admins will only see connections in which they are the business owner or requester.",
  BIT_SIGHT = "This feature integrates with BitSight's API.",
  BULK_MESSAGE = "This feature turns on the Bulk Message feature for the company, but individuals still need the permission set via the Admin screen.",
  BADGES = "This allows the company to set a custom badge that is automatically associated with all suppliers that connect with them.",
  INTERNAL_BADGES = "This allows the company to conditionally show custom badges on this suppliers. Only the company can see these badges.",
  COMMUNITY_FORUM = "This adds a link to the Graphite Community website under the user profile dropdown.",
  CONTACT_CENTER = "This feature makes it so the company will see the contact center on supplier profiles, but individuals still need the permission set via the Admin screen",
  DATA_WAREHOUSE = "This feature allows customers who don't have access to an internal data warehouse to quickly stand up a database that is managed by Graphite for reporting purposes.",
  DUN_BRADSTREET = "This feature integrates with Dun & Bradstreet's API.",
  BUREAU_VAN_DJIK = "This feature integrates with Bureau van Djik's Orbis API.",
  ECO_VADIS = "This feature integrates with EcoVadis's API.",
  GIACT = "This feature integrates with GIACT's API.",
  SAM_GOV = "This feature integrates with SAM.gov's API",
  OVERRIDE_SCORES = "This is what allows topic owners to change the assigned risk/score when doing a topic review. This additionally must be set for the topic in the hammer.",
  OWN_PROFILE_WRITE_RESTRICTION = "This makes it so that users of a company cannot change their own profile unless they are an admin, or unless they are topic owners with the appropriate role for that question (buyer/seller).",
  BUYER_INVITE = "Invitations to Suppliers are disallowed unless this feature is enabled.",
  SELLER_INVITE = "Invitations to Customers are disallowed unless this feature is enabled.",
  // DARK_BEAM
  // DOW_JONES
  // ADOBE_SIGN
  // DOCUSIGN
  // RAPID_RATINGS
  RBA = "This feature integrates with Responsible Business Alliance's API.",
  // SECZETTA
  // ZENDESK
  REPORTS = "This feature allows customers to create and render reports.",
  // RISKRECON
  TRANSFORMATION_EXPORT = "This feature allows customers to download advanced, pre-built reports.",
  DISABLE_OFAC = "This feature disables OFAC if the user has custom screening process.",
  ENABLE_ACTION_PLANS = "This feature enables action plans",
  ENABLE_OPPORTUNITIES = "This feature enables opportunities",
  SUPPLIER_ONBOARDING_STATS = "Display supplier onboarding stats/chart on pro home page",
  CUSTOMER_ONBOARDING_STATS = "Display customer onboarding stats/chart on pro home page",
  TOPIC_REVIEW_STATS = "Display topic review stats/chart on pro home page",
  EXCLUDE_LEGACY_SUPPLIER_STATS = "Exclude connections with legacy suppliers from the stats/chart on pro home page",
  WORKFLOW_BUILDER = "Give access to custom workflow builder",
}

export enum IntegrationType {
  DOCUSIGN = "docusign",
  ADOBE_SIGN = "adobe_sign",
  RAPID_RATINGS = "rapid_ratings",
  RBA = "rba",
  SECZETTA = "seczetta",
  ZENDESK = "zendesk",
  DARK_BEAM = "dark_beam",
  DOW_JONES = "dow_jones",
  DUN_BRADSTREET = "dun_bradstreet",
  BIT_SIGHT = "bit_sight",
  BUREAU_VAN_DJIK = "bureau_van_djik",
  ECO_VADIS = "eco_vadis",
  PROVE_IDENTITY = "prove_identity",
  RISKRECON = "riskrecon",
  REPORTS = "reports",
  SMART_CUBE = "smart_cube",
  VERIFF_IDENTITY = "veriff_identity",
  WORKFLOW_WEBHOOK = "workflow_webhook",
  RETRY_WEBHOOK = "retry_webhook",
  TIN_CHECK = "tin_check",
  GIACT = "giact",
  SLACK = "slack",
  SAM_GOV = "sam_gov",
}

export function convertStringToIntegrationType(value: string): IntegrationType | undefined {
  if ((Object.values(IntegrationType) as string[]).includes(value)) {
    return value as IntegrationType;
  } else {
    return undefined;
  }
}
