import async from "async";
import {KJUR} from "jsrsasign";
import {uniqBy} from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import {
  all,
  always,
  chain,
  Dictionary,
  equals,
  find,
  flatten,
  innerJoin,
  isEmpty,
  isNil,
  map,
  product,
  range,
  reverse,
  sum,
  uniq,
} from "ramda";
import {v4} from "uuid";
import {isGroupAnswer} from "../answers";
import {getFreeTextAnswer} from "../answers/values";
import type {Localizable} from "../api";
import {isLocalizable, localizeExpression} from "../api";
import {isLocalPointer, Pointer} from "../api/answers";
import {ListItemDTO} from "../api/lists";
import {parsePhoneNumberOrThrow} from "../contacts";
import {Locale} from "../enums";
import {MagicAnswerKeys} from "../enums/answers";
import _localeList from "../locales/_localesList.json";
import {nonDefaultNonDeletedInstances} from "../profile/group-util";
import {
  coerceToArray,
  compact,
  ensureArray,
  escapedSplit,
  formatDate,
  JSONObject,
  JSONValue,
  MILLISECONDS_PER_DAY,
  parseDate,
  todayMoment,
} from "../utils";
import factory from "../utils/logging";

const logger = factory.getLogger("Rules");

export enum Operator {
  EQUAL = "=",
  NOT_EQUAL = "!=",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  OR = "or",
  AND = "and",
  IN = "in",
  NOT_IN = "not in",
  LENGTH = "length",
  CONDITIONAL = "?:",
  KEY = "key",
  NOT = "not",
  CONCAT = "concat",
  CONCAT_ARRAY = "concatArray",
  INTERSECTS = "intersects",
  APPLY_CONSTANTS = "applyConstants", // deprecated
  TODAY = "today",
  NOW = "now",
  ADD_DAYS = "addDays",
  ADD_MINUTES = "addMinutes",
  GROUP_VALUE = "groupValue",
  GROUP_VALUES = "groupValues",
  GROUP_INSTANCE = "groupInstance",
  GROUP_INSTANCE_REVERSED = "groupInstanceReversed",
  MARKDOWN_ESCAPE = "markdownEscape",
  REPLACE = "replace",
  MATCH = "match",
  JOIN = "join",
  TO_UPPER = "toUpper",
  TO_LOWER = "toLower",
  URL_ENCODE = "urlEncode",
  ANY = "any",
  ALL = "all",
  MAP = "map",
  FILTER = "filter",
  UNIQUE = "unique",
  DECODE = "decode",
  SUM = "sum",
  PRODUCT = "*",
  DIFFERENCE = "-",
  QUOTIENT = "/",
  COMPACT = "compact",
  GROUP_INSTANCES = "groupInstances",
  WITH_DEPENDENCIES = "withDependencies",
  UNDEFINED = "undefined",
  SUMSCORES = "sum scores", // Should never appear outside the loader!
  ARRAY = "array", // NOT A REAL OPERATOR. JUST TO SIMPLIFY SOME CODE BELOW.
  AUTO_DECODE_CONCAT = "auto_decode_concat", // Should be replaced during parsing
  NO_AUTO_DECODE = "no_auto_decode", // Should be replaced during parsing
  LOCALIZE = "localize",
  PLACEHOLDER = "placeholder", // Must be replaced before evaluation
  DEBUG = "debug",
  COALESCE = "coalesce",
  OBJECT = "object",
  ROUND_TO = "roundTo",
  PICK = "pick",
  UUID = "uuid",
  POINTER_LOOKUP = "pointer_lookup",
  USER_HAS_ROLE = "user_has_role",
  FIND_STRING_IN_FILE = "find_string_in_file",
  FILE_CONTENTS = "fileContents",
  PHONE_NUMBER_BASE = "phoneNumberBase",
  PHONE_NUMBER_COUNTRY = "phoneNumberCountry",
  PHONE_NUMBER_COUNTRY_CODE = "phoneNumberCountryCode",
  JWS_SIGN = "jws_sign",
  SPLIT = "split",
  FORMAT_DATE = "formatDate",
  SUBSTRING = "substring",
  UNACCENT = "unaccent",
  NULL = "null",
  KEYS = "keys",
}

export interface ExpressionOptions {
  skipFormatting?: boolean;
  dateFormat?: string;
  timezone?: string;
  locales?: string[];
  noMarkDownEscape?: boolean;
  debug?: boolean;
  debugString?: string;
  debugLogger?: FancyExpressionLogger;
  decode?: (...args: ExpressionResult[]) => ExpressionResult;
  pointerLookup?: (pointer: Pointer, exp: Expression) => ExpressionResult;
  userHasRole?: (...args: ExpressionResult[]) => ExpressionResult;
  findStringInFile?: (...args: ExpressionResult[]) => ExpressionResult;
  fileContents?: (...args: ExpressionResult[]) => ExpressionResult;
}

export type Expression =
  | {
      [Operator.EQUAL]?: Expression[];
      [Operator.NOT_EQUAL]?: Expression[];
      [Operator.GREATER_THAN]?: Expression[];
      [Operator.LESS_THAN]?: Expression[];
      [Operator.GREATER_THAN_OR_EQUAL]?: Expression[];
      [Operator.LESS_THAN_OR_EQUAL]?: Expression[];
      [Operator.NOT_EQUAL]?: Expression[];
      [Operator.OR]?: Expression[];
      [Operator.AND]?: Expression[];
      [Operator.IN]?: Array<Expression | Expression[]>;
      [Operator.NOT_IN]?: Array<Expression | Expression[]>;
      [Operator.LENGTH]?: Expression;
      [Operator.CONDITIONAL]?: Expression[];
      [Operator.KEY]?: string;
      [Operator.NOT]?: Expression;
      [Operator.CONCAT]?: Expression[];
      [Operator.AUTO_DECODE_CONCAT]?: Expression[];
      [Operator.INTERSECTS]?: Expression[][];
      [Operator.APPLY_CONSTANTS]?: Expression;
      [Operator.TODAY]?: null;
      [Operator.NOW]?: null;
      [Operator.ADD_DAYS]?: Expression[];
      [Operator.ADD_MINUTES]?: Expression[];
      [Operator.GROUP_VALUE]?: Expression[];
      [Operator.GROUP_VALUES]?: Expression[];
      [Operator.GROUP_INSTANCE]?: Expression[];
      [Operator.GROUP_INSTANCE_REVERSED]?: Expression[];
      [Operator.MARKDOWN_ESCAPE]?: Expression;
      [Operator.REPLACE]?: Expression[];
      [Operator.MATCH]?: Expression[];
      [Operator.JOIN]?: Expression[];
      [Operator.TO_UPPER]?: Expression;
      [Operator.TO_LOWER]?: Expression;
      [Operator.URL_ENCODE]?: Expression;
      [Operator.ANY]?: Array<Expression | Expression[]>;
      [Operator.ALL]?: Array<Expression | Expression[]>;
      [Operator.MAP]?: Array<Expression | Expression[]>;
      [Operator.FILTER]?: Array<Expression | Expression[]>;
      [Operator.UNIQUE]?: Array<Expression | Expression[]>;
      [Operator.DECODE]?: Expression[];
      [Operator.COALESCE]?: Expression[];
      [Operator.SUM]?: Expression[];
      [Operator.DIFFERENCE]?: Expression[];
      [Operator.PRODUCT]?: Expression[];
      [Operator.QUOTIENT]?: Expression[];
      [Operator.COMPACT]?: Expression;
      [Operator.GROUP_INSTANCES]?: Expression[];
      [Operator.WITH_DEPENDENCIES]?: Expression[];
      [Operator.SUMSCORES]?: Expression[];
      [Operator.UNDEFINED]?: null;
      [Operator.LOCALIZE]?: Localizable<Expression>;
      [Operator.PLACEHOLDER]?: null;
      [Operator.DEBUG]?: Expression[];
      [Operator.OBJECT]?: Dictionary<Expression>;
      [Operator.ROUND_TO]?: Expression[];
      [Operator.PHONE_NUMBER_BASE]?: Expression;
      [Operator.PHONE_NUMBER_COUNTRY_CODE]?: Expression;
      [Operator.PHONE_NUMBER_COUNTRY]?: Expression;
      [Operator.PICK]?: Expression[];
      [Operator.UUID]?: null;
      [Operator.JWS_SIGN]?: Expression[];
      [Operator.POINTER_LOOKUP]?: Expression[];
      [Operator.USER_HAS_ROLE]?: Expression[];
      [Operator.FIND_STRING_IN_FILE]?: Expression[];
      [Operator.FILE_CONTENTS]?: Expression[];
      [Operator.SPLIT]?: Expression[];
      [Operator.FORMAT_DATE]?: Expression[];
      [Operator.SUBSTRING]?: Expression[];
      [Operator.UNACCENT]?: Expression;
      [Operator.NULL]?: null;
      [Operator.KEYS]?: Expression;
      caseInsensitive?: boolean;
    }
  | boolean
  | string
  | number
  | null;
// An always-false expression that is truthy JS
export const NEVER = {[Operator.NOT]: true};
export const setVisibilityRule = (rule, skipTrue = true) =>
  rule === false ? NEVER : rule === true && skipTrue ? undefined : rule;
export const FIRST = (groupKey: Expression, answerKey: Expression) => ({
  [Operator.GROUP_VALUE]: [groupKey, {[Operator.GROUP_INSTANCE]: [groupKey, true]}, answerKey],
});
export const LAST = (groupKey: Expression, answerKey: Expression) => ({
  [Operator.GROUP_VALUE]: [groupKey, {[Operator.GROUP_INSTANCE_REVERSED]: [groupKey, true]}, answerKey],
});
export const ALL = (groupKey: Expression) => ({[Operator.GROUP_INSTANCES]: [groupKey, true]});
export const ARG0 = "__arg0";
export const MATCH_ANY = (needle: Expression, regexHay: Expression | Expression[], flags?: Expression) => ({
  [Operator.ANY]: [
    {[Operator.MATCH]: flags ? [needle, {[Operator.KEY]: ARG0}, flags] : [needle, {[Operator.KEY]: ARG0}]},
    regexHay,
  ],
});

export function isNeverExpression(expression: Expression | undefined): boolean {
  if (isConstantExpression(expression)) {
    return false;
  }
  const {operator, args} = getOperatorAndArgs(expression);
  return operator === Operator.NOT && args === true;
}

function uniqueAnswerKeys(...exps: ExpressionEvaluator[]): string[] {
  return uniq(chain((e) => e.answerKeys, exps).filter((k) => k !== ARG0));
}

function handleEmpty(x: any) {
  // tslint:disable-next-line:triple-equals Undefined or null becomes empty for consistent comparisons.
  return x == undefined ? "" : x;
}

function flattenAndDedupeAndArgs(exps: Array<Expression | null | undefined>) {
  let definedExps: Expression[] = exps.filter((exp): exp is Expression => !isNil(exp));
  for (let idx = 0; idx < definedExps.length; ) {
    const expression = definedExps[idx];
    if (isConstantExpression(expression)) {
      if (expression) {
        definedExps.splice(idx, 1);
        continue;
      } else {
        return [NEVER];
      }
    }

    const operator = Object.keys(expression)[0];
    if (operator === Operator.AND) {
      definedExps.splice(idx, 1);
      definedExps = definedExps.concat(expression[operator]!);
    } else {
      idx++;
    }
  }
  definedExps = uniq(definedExps);
  return definedExps;
}

export function logicalAnd(...exps: Array<Expression | null | undefined>): Expression | undefined {
  const definedExps = flattenAndDedupeAndArgs(exps);
  if (definedExps.length === 0) {
    return true;
  } else if (definedExps.length === 1) {
    return simplifyExpression(definedExps[0]);
  } else {
    return simplifyExpression({[Operator.AND]: definedExps});
  }
}

export function logicalOr(...exps: Array<Expression | null | undefined>): Expression | undefined {
  const definedExps = flattenAndDedupeAndArgs(exps);
  if (definedExps.length === 0) {
    return false;
  } else if (definedExps.length === 1) {
    return simplifyExpression(definedExps[0]);
  } else {
    return simplifyExpression({[Operator.OR]: definedExps});
  }
}

export type SingleExpressionResult = any;
export type ExpressionResult = SingleExpressionResult[] | SingleExpressionResult;

function makeGroupKeys(...parts: Expression[]): string[] {
  return subKeys(parts.map((p) => (typeof p === "string" ? p : "*")).join("."));
}

function replace(value: string, regex: string, replacement: string, flags: string): string {
  return value.replace(new RegExp(regex, flags), replacement);
}

function match(value: string, regex: string, flags: string): boolean {
  return value.match(new RegExp(regex, flags)) !== null;
}

export function subKeys(key: string): string[] {
  const keySplit = key.split(".");
  return range(0, keySplit.length).map((idx) => keySplit.slice(0, idx + 1).join("."));
}

export const isOperator = (operator: Operator, exp: any) => typeof exp === "object" && exp !== null && operator in exp;

export function isComparisonOperator(operator: Operator) {
  switch (operator) {
    case Operator.EQUAL:
    case Operator.NOT_EQUAL:
    case Operator.GREATER_THAN:
    case Operator.LESS_THAN:
    case Operator.GREATER_THAN_OR_EQUAL:
    case Operator.LESS_THAN_OR_EQUAL:
    case Operator.IN:
    case Operator.NOT_IN:
      return true;
    default:
      return false;
  }
}

export function isCurrentUserKey(expression: Expression) {
  return isOperator(Operator.KEY, expression) && expression![Operator.KEY] === MagicAnswerKeys.CURRENT_USER;
}

const caseInsensitiveEquals = (a: string, b: string): boolean =>
  a.localeCompare(b, undefined, {sensitivity: "accent"}) === 0;
const resultEquals = (a: ExpressionResult, b: ExpressionResult, caseInsensitive?: boolean): boolean =>
  caseInsensitive && typeof a === "string" && typeof b === "string" ? caseInsensitiveEquals(a, b) : equals(a, b);

function isDateString(result): result is string {
  return typeof result === "string" && /^\d\d\d\d-\d\d-\d\d/.test(result);
}

function getLocaleAnswer(answer: any, locales: string[] | undefined) {
  for (const locale of locales || []) {
    const result = getFreeTextAnswer(answer, locale);
    if (result !== undefined) {
      return result;
    }
  }
  return getFreeTextAnswer(answer);
}

function orderComparison(
  lhs: ExpressionResult,
  rhs: ExpressionResult,
  operator: Operator.GREATER_THAN | Operator.LESS_THAN | Operator.GREATER_THAN_OR_EQUAL | Operator.LESS_THAN_OR_EQUAL,
) {
  if (lhs instanceof Date && typeof rhs === "string") {
    rhs = new Date(rhs);
  } else if (rhs instanceof Date && typeof lhs === "string") {
    lhs = new Date(lhs);
  } else if (!isNaN(rhs as any) && !isNaN(lhs as any)) {
    lhs = Number(lhs);
    rhs = Number(rhs);
  }

  switch (operator) {
    case Operator.GREATER_THAN:
      return lhs! > rhs!;
    case Operator.LESS_THAN:
      return lhs! < rhs!;
    case Operator.GREATER_THAN_OR_EQUAL:
      return lhs! >= rhs!;
    case Operator.LESS_THAN_OR_EQUAL:
      return lhs! <= rhs!;
  }
}

export class ExpressionEvaluator {
  public static formatResult(result: ExpressionResult, options: {dateFormat?: string; timezone?: string}) {
    if ((result instanceof Date || isDateString(result)) && options.dateFormat) {
      const date = moment(result);
      if (options.timezone) {
        date.tz(options.timezone);
      }
      return date.format(options.dateFormat);
    } else if (Array.isArray(result)) {
      return result.map((r) => ExpressionEvaluator.formatResult(r, options)).join(", ");
    } else {
      return result;
    }
  }

  public evaluate: (...data: JSONObject[]) => ExpressionResult;
  public readonly answerKeys: string[] = [];
  private readonly options: ExpressionOptions;

  public constructor(public readonly expression: Expression | Date | undefined, options?: ExpressionOptions) {
    this.options = options || {};
    if (
      typeof expression === "boolean" ||
      typeof expression === "number" ||
      typeof expression === "string" ||
      typeof expression === "undefined" ||
      expression === null ||
      expression instanceof Date
    ) {
      this.evaluate = () => expression as ExpressionResult;
      this.answerKeys = [];
      return;
    }

    const {operator, args} = getOperatorAndArgs(expression);

    switch (operator) {
      case Operator.EQUAL: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [lhs, rhs] = map((e) => new ExpressionEvaluator(e, this.options), args);
        lhs.options.locales = ["en-US"];
        rhs.options.locales = ["en-US"];
        this.answerKeys = uniqueAnswerKeys(lhs, rhs);
        this.evaluate = (...data) => {
          const lhsResult = handleEmpty(lhs.evaluate(...data));
          const rhsResult = handleEmpty(rhs.evaluate(...data));
          return resultEquals(lhsResult, rhsResult, expression.caseInsensitive ?? true);
        };
        break;
      }

      case Operator.NOT_EQUAL: {
        const exp = new ExpressionEvaluator(
          {[Operator.NOT]: {[Operator.EQUAL]: args, caseInsensitive: expression.caseInsensitive}},
          this.options,
        );
        this.answerKeys = exp.answerKeys;
        this.evaluate = exp.evaluate;
        break;
      }

      case Operator.GREATER_THAN:
      case Operator.LESS_THAN:
      case Operator.GREATER_THAN_OR_EQUAL:
      case Operator.LESS_THAN_OR_EQUAL: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [lhs, rhs]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(lhs, rhs);
        this.evaluate = (...data) => {
          const lhsResult: SingleExpressionResult[] | boolean | string | number | Date | null | undefined =
            lhs.evaluate(...data);
          const rhsResult = rhs.evaluate(...data);
          return orderComparison(lhsResult, rhsResult, operator);
        };
        break;
      }

      case Operator.KEY: {
        this.answerKeys = subKeys(args);
        const keyPath = args.split(".");
        this.evaluate = (...answers) => this.getAnswer(keyPath, ...answers);
        break;
      }

      case Operator.GROUP_VALUE:
      case Operator.GROUP_VALUES: {
        const [groupKeyExp, instanceIdExp, answerKeyExp]: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          args,
        );
        this.answerKeys = uniq(instanceIdExp.answerKeys.concat(makeGroupKeys(...args)));
        this.evaluate = (...answers) => {
          const groupKey = groupKeyExp.evaluate(...answers);
          const instanceIds = ensureArray(instanceIdExp.evaluate(...answers));
          const answerKey = answerKeyExp.evaluate(...answers);
          if (typeof groupKey !== "string" || typeof answerKey !== "string") {
            return undefined;
          }
          const result = instanceIds.map<SingleExpressionResult>((instanceId) => {
            if (typeof instanceId !== "string") {
              return undefined;
            }
            const fullPath = [...groupKey.split("."), instanceId, ...answerKey.split(".")];
            return this.getAnswer(fullPath, ...answers);
          });
          return operator === Operator.GROUP_VALUE ? result[0] : result;
        };
        break;
      }

      case Operator.GROUP_INSTANCE:
      case Operator.GROUP_INSTANCE_REVERSED:
      case Operator.GROUP_INSTANCES: {
        const [groupKeyExp, matcherExp]: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          args,
        );
        if (isEmpty(matcherExp.answerKeys)) {
          this.answerKeys = makeGroupKeys(args[0]);
        } else {
          this.answerKeys = uniq(
            matcherExp.answerKeys.concat(uniq(chain((k) => makeGroupKeys(args[0], "*", k), matcherExp.answerKeys))),
          );
        }
        this.evaluate = (...answers) => {
          const groupKey = groupKeyExp.evaluate(...answers);
          if (typeof groupKey !== "string") {
            return undefined;
          }
          const group: {} = this.getAnswer(groupKey.split("."), ...answers);
          let instances = nonDefaultNonDeletedInstances(group);
          if (operator === Operator.GROUP_INSTANCE_REVERSED) {
            instances = reverse(instances);
          }
          const result: string[] = [];
          for (const instanceId of instances) {
            const matcherResult = matcherExp.evaluate(
              {[MagicAnswerKeys.INSTANCE_ID]: instanceId},
              group[instanceId] || {},
              ...answers,
            );
            if (matcherResult) {
              result.push(instanceId);
              if (operator !== Operator.GROUP_INSTANCES) {
                return instanceId;
              }
            }
          }
          return operator === Operator.GROUP_INSTANCES ? result : result[0];
        };
        break;
      }

      case Operator.AND: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          return !Boolean(find((exp) => !Boolean(exp.evaluate(...data)), exps));
        };
        break;
      }

      case Operator.OR: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          return Boolean(find((exp) => Boolean(exp.evaluate(...data)), exps));
        };
        break;
      }

      case Operator.IN: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const needle = new ExpressionEvaluator(args[0], this.options);
        const hay: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), ensureArray(args[1]));
        this.answerKeys = uniqueAnswerKeys(needle, ...hay);
        this.evaluate = (...data) => {
          const needleValue = handleEmpty(needle.evaluate(...data));
          const haystack = chain((e) => {
            const result = e.evaluate(...data);
            return ensureArray(result);
          }, hay)
            .map(handleEmpty)
            .flat();
          return !!find((v) => resultEquals(v, needleValue, expression.caseInsensitive), haystack);
        };
        break;
      }

      case Operator.NOT_IN: {
        const exp = new ExpressionEvaluator({[Operator.NOT]: {[Operator.IN]: args}}, this.options);
        this.answerKeys = exp.answerKeys;
        this.evaluate = exp.evaluate;
        break;
      }

      case Operator.LENGTH: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result: SingleExpressionResult[] | boolean | string | number | Date | null | undefined =
            operand.evaluate(...data);
          return typeof result === "string" || Array.isArray(result)
            ? result.length
            : isGroupAnswer(result)
            ? nonDefaultNonDeletedInstances(result).length
            : !isNil(result) && !isEmpty(result)
            ? 1
            : 0;
        };
        break;
      }

      case Operator.CONDITIONAL: {
        if (args.length < 3 || args.length % 2 !== 1) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const operands: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...operands);
        this.evaluate = (...data) => {
          for (let idx = 0; idx < operands.length - 1; idx += 2) {
            if (operands[idx].evaluate(...data)) {
              return operands[idx + 1].evaluate(...data);
            }
          }
          return operands[operands.length - 1].evaluate(...data);
        };
        break;
      }

      case Operator.NOT: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result = operand.evaluate(...data);
          return !result;
        };
        break;
      }

      case Operator.CONCAT:
      case Operator.AUTO_DECODE_CONCAT: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), coerceToArray(args));
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const pieces = exps.map((e) => {
            const result = e.evaluate(...data);
            if (!this.options.skipFormatting) {
              return ExpressionEvaluator.formatResult(result, this.options);
            } else {
              return result;
            }
          });
          return pieces.join("");
        };
        break;
      }

      case Operator.CONCAT_ARRAY: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), coerceToArray(args));
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const pieces = exps.map((e) => {
            const result = e.evaluate(...data);
            return ensureArray(result);
          });
          return flatten<SingleExpressionResult>(pieces).filter((v) => !isNil(v));
        };
        break;
      }

      case Operator.INTERSECTS: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const needles: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          ensureArray(args[0]),
        );
        const hay: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), ensureArray(args[1]));
        this.answerKeys = uniqueAnswerKeys(...needles, ...hay);
        this.evaluate = (...data) => {
          const needlestack = chain((e) => {
            const result = e.evaluate(...data);
            return coerceToArray(result);
          }, needles).map(handleEmpty);
          const haystack = chain((e) => {
            const result = e.evaluate(...data);
            return coerceToArray(result);
          }, hay).map(handleEmpty);

          return innerJoin((a, b) => resultEquals(a, b, expression.caseInsensitive), needlestack, haystack).length > 0;
        };
        break;
      }

      case Operator.APPLY_CONSTANTS: {
        logger.warn("APPLY_CONSTANTS found in expression.");
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = operand.answerKeys;
        this.evaluate = operand.evaluate;
        break;
      }

      case Operator.TODAY: {
        if (args !== null) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        this.answerKeys = [];
        this.evaluate = () => {
          return formatDate(todayMoment(this.options.timezone));
        };
        break;
      }

      case Operator.NOW: {
        if (args !== null) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        this.answerKeys = [];
        this.evaluate = () => {
          return new Date();
        };
        break;
      }

      case Operator.ADD_DAYS: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [date, days]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(date, days);
        this.evaluate = (...data) => {
          const dateResult = handleEmpty(date.evaluate(...data));
          if (dateResult === "") {
            return dateResult;
          }
          const daysResult = days.evaluate(...data) as number;
          return formatDate(parseDate(dateResult).add(daysResult, "d"));
        };
        break;
      }

      case Operator.ADD_MINUTES: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [date, minutes]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(date, minutes);
        this.evaluate = (...data) => {
          const dateResult = handleEmpty(date.evaluate(...data));
          if (dateResult === "") {
            return dateResult;
          }
          const minutesResult = minutes.evaluate(...data) as number;
          return moment(dateResult).add(minutesResult, "m").toDate();
        };
        break;
      }

      case Operator.MARKDOWN_ESCAPE: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const value = operand.evaluate(...data);
          if (this.options.noMarkDownEscape) {
            return value;
          }
          return replace(String(value || ""), "([\\\\`*_\\[\\]{}()#+\\-.!])", "\\$1", "g");
        };
        break;
      }

      case Operator.REPLACE: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          let [value, regex, replacement, flags] = exps.map((e) => e.evaluate(...data));
          if (flags === undefined) {
            flags = "g";
          }
          return replace(String(value || ""), String(regex), String(replacement), String(flags));
        };
        break;
      }

      case Operator.MATCH: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const [value, regex, flags] = exps.map((e) => e.evaluate(...data));
          return regex && match(String(value || ""), String(regex), String(flags || ""));
        };
        break;
      }

      case Operator.JOIN: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const [values, delimiter] = exps.map((e) => e.evaluate(...data));
          return ensureArray(values)
            .map((v) => ExpressionEvaluator.formatResult(v, this.options))
            .join(ExpressionEvaluator.formatResult(delimiter, this.options));
        };
        break;
      }

      case Operator.TO_UPPER: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result = operand.evaluate(...data);
          const stringResult = result !== undefined && result !== null ? String(result) : "";
          return stringResult.toUpperCase();
        };
        break;
      }

      case Operator.TO_LOWER: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result = operand.evaluate(...data);
          const stringResult = result !== undefined && result !== null ? String(result) : "";
          return stringResult.toLowerCase();
        };
        break;
      }

      case Operator.URL_ENCODE: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result = operand.evaluate(...data);
          const stringResult = result !== undefined && result !== null ? String(result) : "";
          return encodeURIComponent(stringResult);
        };
        break;
      }

      case Operator.ANY:
      case Operator.ALL: {
        const f = new ExpressionEvaluator(args[0], this.options);
        const hay: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), ensureArray(args[1]));
        this.answerKeys = uniqueAnswerKeys(f, ...hay);
        this.evaluate = (...data) => {
          const haystack = chain((e) => {
            const result = e.evaluate(...data);
            return coerceToArray(result);
          }, hay).map(handleEmpty);

          for (const straw of haystack) {
            const result = f.evaluate({[ARG0]: straw}, ...data);
            if (result === (operator === Operator.ANY)) {
              return result;
            }
          }
          return operator === Operator.ALL;
        };
        break;
      }

      case Operator.MAP: {
        const mapper = new ExpressionEvaluator(args[0], this.options);
        const argArray: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          ensureArray(args[1]),
        );
        this.answerKeys = uniqueAnswerKeys(mapper, ...argArray);
        this.evaluate = (...data) => {
          const toMap = chain((e) => {
            const result = e.evaluate(...data);
            return coerceToArray(result);
          }, argArray).map(handleEmpty);

          const results: any[] = [];
          for (const value of toMap) {
            const result = mapper.evaluate({[ARG0]: value}, ...data);
            results.push(result);
          }
          return results;
        };
        break;
      }

      case Operator.FILTER: {
        const filter = new ExpressionEvaluator(args[0], this.options);
        const argArray: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          ensureArray(args[1]),
        );
        this.answerKeys = uniqueAnswerKeys(filter, ...argArray);
        this.evaluate = (...data) => {
          const toFilter = chain((e) => {
            const result = e.evaluate(...data);
            return coerceToArray(result);
          }, argArray).map(handleEmpty);

          const results: any[] = [];
          for (const value of toFilter) {
            if (filter.evaluate({[ARG0]: value}, ...data)) {
              results.push(value);
            }
          }
          return results;
        };
        break;
      }

      case Operator.UNIQUE: {
        const [argBy, argArr] = map((e) => new ExpressionEvaluator(e, this.options), coerceToArray(args));
        this.answerKeys = uniqueAnswerKeys(argArr, argBy);
        this.evaluate = (...data) => {
          const arr = argArr.evaluate(...data);
          const by = argBy.evaluate(...data);

          if (by) {
            return uniqBy(arr, by);
          }
          return uniq(arr);
        };

        break;
      }

      case Operator.DECODE: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        const decode = this.options.decode;
        if (decode) {
          this.evaluate = (...data) => {
            const argValues = exps.map((exp) => exp.evaluate(...data));
            return decode(...argValues);
          };
        } else {
          this.evaluate = exps[0].evaluate.bind(exps[0]);
        }
        break;
      }

      case Operator.WITH_DEPENDENCIES: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = exps[0].evaluate.bind(exps[0]);
        break;
      }

      case Operator.COALESCE: {
        const elements: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...elements);
        this.evaluate = (...data) => {
          const evaluated = elements.map((e) => e.evaluate(...data));
          return evaluated.find((r) => {
            return !!r;
          }) as SingleExpressionResult;
        };
        break;
      }

      case Operator.UNDEFINED:
        this.answerKeys = [];
        this.evaluate = always(undefined);
        break;

      case Operator.ARRAY: {
        const elements: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...elements);
        this.evaluate = (...data) => elements.map((e) => e.evaluate(...data)) as SingleExpressionResult[];
        break;
      }

      case Operator.SUM: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), coerceToArray(args));
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const chained = chain<ExpressionEvaluator, SingleExpressionResult>(
            (exp) => ensureArray(exp.evaluate(...data)),
            exps,
          );
          return sum(chained.map((v) => Number(v || 0)));
        };
        break;
      }

      case Operator.PRODUCT: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          const chained = chain<ExpressionEvaluator, SingleExpressionResult>(
            (exp) => ensureArray(exp.evaluate(...data)),
            exps,
          );
          return product(chained.map((v) => Number(v || 0)));
        };
        break;
      }

      case Operator.DIFFERENCE: {
        const [minuend, subtrahend]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(minuend, subtrahend);
        this.evaluate = (...data) => {
          const minuendResult = Number(minuend.evaluate(...data) || 0);
          const subtrahendResult = Number(subtrahend.evaluate(...data) || 0);
          return minuendResult - subtrahendResult;
        };
        break;
      }

      case Operator.QUOTIENT: {
        const [dividend, divisor]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(dividend, divisor);
        this.evaluate = (...data) => {
          const dividendResult = Number(dividend.evaluate(...data) || 0);
          const divisorResult = Number(divisor.evaluate(...data) || 0);
          return dividendResult / divisorResult;
        };
        break;
      }

      case Operator.COMPACT: {
        const operand = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(operand);
        this.evaluate = (...data) => {
          const result = operand.evaluate(...data);
          return compact(coerceToArray(result).map((r) => (isEmpty(r) ? undefined : r)));
        };
        break;
      }

      case Operator.SUMSCORES:
        this.answerKeys = [];
        this.evaluate = () => {
          throw new Error(
            "SumScores expression evaluated without conversion to simple sum: " + JSON.stringify(expression),
          );
        };
        break;

      case Operator.LOCALIZE: {
        const arg = new ExpressionEvaluator(args[Locale.EN_US], this.options);
        this.answerKeys = uniqueAnswerKeys(arg);
        this.evaluate = () => {
          throw new Error("Localize expression evaluated without localization: " + JSON.stringify(expression));
        };
        break;
      }

      case Operator.PLACEHOLDER:
        this.answerKeys = [];
        this.evaluate = () => {
          throw new Error("Placeholder expression evaluated without replacement");
        };
        break;

      case Operator.DEBUG: {
        const argsArray = coerceToArray(args);
        const debugString = (argsArray[1] as string) || undefined;
        const exp = new ExpressionEvaluator(argsArray[0], {...this.options, debug: true, debugString});
        this.answerKeys = exp.answerKeys;
        this.evaluate = exp.evaluate;
        break;
      }

      case Operator.OBJECT: {
        const evaluatorObject: Dictionary<ExpressionEvaluator> = map(
          (v) => new ExpressionEvaluator(v, this.options),
          args,
        );
        this.answerKeys = uniqueAnswerKeys(...Object.values(evaluatorObject));
        this.evaluate = (...data) => {
          const result = map((e) => e.evaluate(...data), evaluatorObject) as any as Dictionary<SingleExpressionResult>;
          return result;
        };
        break;
      }

      case Operator.ROUND_TO: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [val, decimalPlaces]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(val, decimalPlaces);
        this.evaluate = (...data) => {
          const valResult = val.evaluate(...data) as number;
          const decimalPlacesResult = decimalPlaces.evaluate(...data) as number;
          return Math.round(valResult * 10 ** decimalPlacesResult) / 10 ** decimalPlacesResult;
        };
        break;
      }

      case Operator.PICK: {
        if (args.length !== 2) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        const [key, target]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(key, target);
        this.evaluate = (...data) => {
          const keyResult = String(key.evaluate(...data));
          const targetResult = target.evaluate(...data);
          return fancyPath(keyResult.split("."), targetResult);
        };
        break;
      }

      case Operator.UUID: {
        if (args !== null) {
          throw new Error("Invalid expression: " + JSON.stringify(expression));
        }
        this.answerKeys = [];
        this.evaluate = () => {
          return v4();
        };
        break;
      }

      case Operator.NULL: {
        this.evaluate = always(null);
        break;
      }

      case Operator.POINTER_LOOKUP: {
        const [pointerExp, targetExp]: ExpressionEvaluator[] = map(
          (e) => new ExpressionEvaluator(e, this.options),
          args,
        );
        this.answerKeys = uniqueAnswerKeys(pointerExp, targetExp);
        const pointerLookup = this.options.pointerLookup;
        if (pointerLookup) {
          this.evaluate = (...data) => {
            const pointer = pointerExp.evaluate(...data);
            if (isNil(pointer)) {
              return targetExp.evaluate(...data);
            } else if (isLocalPointer(pointer)) {
              if (pointer.groupKey && pointer.instanceId) {
                const groupInstance = this.getAnswer([pointer.groupKey, pointer.instanceId], ...data);
                return targetExp.evaluate(groupInstance, ...data);
              } else {
                return targetExp.evaluate(...data);
              }
            } else {
              return pointerLookup(pointer, args[1]);
            }
          };
        } else {
          this.evaluate = () => undefined;
        }
        break;
      }

      case Operator.USER_HAS_ROLE: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        const userHasRole = this.options.userHasRole;
        if (userHasRole) {
          this.evaluate = (...data) => {
            const argValues = exps.map((exp) => exp.evaluate(...data));
            return userHasRole(...argValues);
          };
        } else {
          this.evaluate = () => {
            return false;
          };
        }
        break;
      }

      case Operator.FIND_STRING_IN_FILE: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        const findStringInFile = this.options.findStringInFile;
        if (findStringInFile) {
          this.evaluate = (...data) => {
            const argValues = exps.map((exp) => exp.evaluate(...data));
            return findStringInFile(...argValues);
          };
        } else {
          this.evaluate = () => {
            return false;
          };
        }
        break;
      }

      case Operator.FILE_CONTENTS: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        const fileContents = this.options.fileContents;
        if (fileContents) {
          this.evaluate = (...data) => {
            const argValues = exps.map((exp) => exp.evaluate(...data));
            return fileContents(...argValues);
          };
        } else {
          this.evaluate = () => {
            return false;
          };
        }
        break;
      }

      case Operator.PHONE_NUMBER_BASE:
      case Operator.PHONE_NUMBER_COUNTRY:
      case Operator.PHONE_NUMBER_COUNTRY_CODE: {
        const phoneNumber = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(phoneNumber);

        this.evaluate = (...data) => {
          const argValue = phoneNumber.evaluate(...data);
          if (!argValue) {
            return undefined;
          }
          try {
            const parsed = parsePhoneNumberOrThrow(argValue);
            if (parsed) {
              switch (operator) {
                case Operator.PHONE_NUMBER_BASE:
                  return parsed.nationalNumber;
                case Operator.PHONE_NUMBER_COUNTRY:
                  return parsed.country;
                case Operator.PHONE_NUMBER_COUNTRY_CODE:
                  return parsed.countryCallingCode;
              }
            }
          } catch (e) {
            logger.warn(`Error parsing phone number ${argValue}: ${e}`);
            return undefined;
          }
          return undefined;
        };
        break;
      }

      case Operator.JWS_SIGN: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);

        this.evaluate = (...data) => {
          const params = exps.map((exp) => exp.evaluate(...data));
          if (!params) {
            return undefined;
          }

          const [header, payload, secret] = params;

          if (!header.alg) {
            throw new Error(`Invalid expression: ${JSON.stringify(expression)}. Header must include arg`);
          }

          return KJUR.jws.JWS.sign(null, JSON.stringify(header), JSON.stringify(payload), secret);
        };
        break;
      }

      case Operator.SPLIT: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          let [value, delimiter] = exps.map((e) => e.evaluate(...data));
          if (delimiter === undefined) {
            delimiter = ",";
          }
          return escapedSplit(delimiter, value);
        };
        break;
      }

      case Operator.NO_AUTO_DECODE: {
        this.answerKeys = [];
        this.evaluate = () => {
          throw new Error("NoAutoDecode expression evaluated");
        };
        break;
      }

      case Operator.FORMAT_DATE: {
        const [date, format]: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(date, format);
        this.evaluate = (...data) => {
          const dateResult = date.evaluate(...data);
          const formatResult = format.evaluate(...data);
          if (formatResult === "x" || formatResult === "X") {
            return moment.utc(dateResult).format(formatResult);
          } else {
            return formatDate(parseDate(dateResult), formatResult);
          }
        };
        break;
      }

      case Operator.SUBSTRING: {
        const exps: ExpressionEvaluator[] = map((e) => new ExpressionEvaluator(e, this.options), args);
        this.answerKeys = uniqueAnswerKeys(...exps);
        this.evaluate = (...data) => {
          let [inputString, start, end] = exps.map((exp) => exp.evaluate(...data));

          if (typeof inputString !== "string") {
            inputString = String(inputString || "");
          }

          start = parseInt(start, 10);
          end = end === undefined ? inputString.length : parseInt(end, 10);

          if (isNaN(start) || isNaN(end)) {
            throw new Error("Invalid expression - Start and end are not numbers: " + JSON.stringify(exps));
          }

          return inputString.substring(start, end);
        };
        break;
      }

      case Operator.UNACCENT: {
        const accentedExp = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(accentedExp);
        this.evaluate = (...data) => {
          const accentedResult: string = accentedExp.evaluate(...data);
          if (typeof accentedResult !== "string") {
            return accentedResult;
          }

          // Magic...
          // Normalize splits characters with diacritical marks into the base character and the mark
          // Removes the accent by removing all characters in the unicode range for diacritical marks
          return accentedResult.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };
        break;
      }

      case Operator.KEYS: {
        const obj = new ExpressionEvaluator(args, this.options);
        this.answerKeys = uniqueAnswerKeys(obj);
        this.evaluate = (...data) => {
          const objResult = obj.evaluate(...data);
          if (!objResult || typeof objResult !== "object" || Array.isArray(objResult)) {
            return [];
          }

          return Object.keys(objResult);
        };
        break;
      }
    }

    if (this.options.debug) {
      this.logEvaluation();
    }
  }

  public toString(): string {
    return JSON.stringify(this.expression);
  }

  private getAnswer(keyPath: string[], ...data: JSONObject[]) {
    for (const answerDoc of data) {
      if (answerDoc && keyPath[0] in answerDoc) {
        // Return a clone, in case the front-end reactively displays and then modifies it.
        return cloneDeep(getLocaleAnswer(fancyPath(keyPath, answerDoc), this.options.locales));
      }
    }
  }

  private logEvaluation() {
    const originalEvaluate = this.evaluate;
    this.evaluate = (...data) => {
      const result = originalEvaluate(...data);
      if (this.options.debugLogger) {
        this.options.debugLogger.log(this.expression, result);
      } else {
        const msg = (this.options.debugString ? `[${this.options.debugString}] ` : "") + `${this} => ${result}`;
        logger.debug(msg);
      }
      return result;
    };
  }
}

function fancyPath(keyPath: string[], answer: JSONValue) {
  let value = answer;
  for (const key of keyPath) {
    if (Array.isArray(value) && isNaN(+key)) {
      value = value.map((v) => (v ?? {})[key]);
    } else {
      value = (value ?? {})[key];
    }
  }
  return value;
}

export function isConstantExpression(
  expression: Expression | undefined,
): expression is boolean | number | string | null | undefined {
  return (
    typeof expression === "boolean" ||
    typeof expression === "number" ||
    typeof expression === "string" ||
    typeof expression === "undefined" ||
    expression === null
  );
}

const allOperators: Set<string> = new Set([...Object.values(Operator), ...Object.keys(_localeList)]);
export function getOperatorAndArgs(expression: Expression & object) {
  let operator: Operator;
  let args: any;
  if (Array.isArray(expression)) {
    operator = Operator.ARRAY;
    args = expression;
  } else {
    const operators = Object.keys(expression).filter((k) => allOperators.has(k));
    if (operators.length !== 1) {
      throw new Error("Invalid expression: " + JSON.stringify(expression));
    }
    operator = operators[0] as Operator;
    args = expression[operator];
  }
  return {operator, args};
}

export function simplifyExpression(
  expression: Expression,
  preserveDependencies: boolean = false,
  noShortCircuit: boolean = false,
): Expression {
  expression = simplifyArgs(expression, preserveDependencies, noShortCircuit);
  if (isConstantExpression(expression)) {
    return expression;
  }

  let simplified: Expression = simplifyOperator(expression, noShortCircuit);
  if (preserveDependencies) {
    const simplifiedAnswerKeys = new ExpressionEvaluator(simplified).answerKeys;
    const originalAnswerKeys = new ExpressionEvaluator(expression).answerKeys;
    if (simplifiedAnswerKeys.length !== originalAnswerKeys.length) {
      simplified = {
        [Operator.WITH_DEPENDENCIES]: [
          simplified,
          ...originalAnswerKeys.filter((k) => simplifiedAnswerKeys.indexOf(k) < 0).map((k) => ({[Operator.KEY]: k})),
        ],
      };
    }
  }
  return simplified;
}

function simplifyArgs(expression: Expression, preserveDependencies: boolean, noShortCircuit: boolean): Expression {
  if (isConstantExpression(expression)) {
    return expression;
  }

  const {operator, args} = getOperatorAndArgs(expression);
  if (operator === Operator.LOCALIZE) {
    return expression;
  } else if (operator === Operator.OBJECT) {
    const expObject: Dictionary<Expression> = args;
    return {
      [operator]: map(
        (e) => simplifyExpression(e, preserveDependencies, noShortCircuit),
        expObject,
      ) as Dictionary<Expression>,
    };
  }

  const simplifiedArgs = Array.isArray(args)
    ? args.map((arg) => simplifyExpression(arg, preserveDependencies, noShortCircuit))
    : simplifyExpression(args, preserveDependencies, noShortCircuit);

  if (operator === Operator.ARRAY) {
    return simplifiedArgs as Expression;
  } else {
    const result = {[operator]: simplifiedArgs};
    if (expression.caseInsensitive !== undefined) {
      result.caseInsensitive = expression.caseInsensitive;
    }
    return result;
  }
}

/*
 * Applies a transformation to an expression. The callback function should return the transformation of the
 * sub-expression, or return undefined to indicate no transformation and let processExpression recurse on the arguments.
 */
export function processExpression(
  expression: Expression | undefined,
  fn: (operator: Operator, args: any, expression: Expression) => Expression | undefined,
) {
  if (isConstantExpression(expression)) {
    return expression;
  }
  const {operator, args} = getOperatorAndArgs(expression);
  const processed = fn(operator, args, expression);
  if (!isNil(processed)) {
    return processed;
  } else if (operator === Operator.OBJECT || operator === Operator.LOCALIZE) {
    const expObject: Dictionary<Expression> = args;
    return {[operator]: map((e) => processExpression(e, fn), expObject) as Dictionary<Expression>};
  }

  const processedArgs = Array.isArray(args)
    ? args.map((arg) => processExpression(arg, fn))
    : processExpression(args, fn);

  if (operator === Operator.ARRAY) {
    return processedArgs as Expression;
  } else {
    const result = {[operator]: processedArgs};
    if (expression.caseInsensitive !== undefined) {
      result.caseInsensitive = expression.caseInsensitive;
    }
    return result;
  }
}

export async function processExpressionAsync(
  expression: Expression | undefined,
  fn: (operator: Operator, args: any, expression: Expression) => Promise<Expression | undefined>,
) {
  if (isConstantExpression(expression)) {
    return expression;
  }
  const {operator, args} = getOperatorAndArgs(expression);
  const processed = await fn(operator, args, expression);
  if (!isNil(processed)) {
    return processed;
  } else if (operator === Operator.OBJECT) {
    const expObject: Dictionary<Expression> = args;
    return {
      [operator]: (await async.mapValues(
        expObject,
        async (e) => await processExpressionAsync(e, fn),
      )) as Dictionary<Expression>,
    };
  }

  const processedArgs = Array.isArray(args)
    ? await async.map(args, async (arg) => await processExpressionAsync(arg, fn))
    : await processExpressionAsync(args, fn);

  if (operator === Operator.ARRAY) {
    return processedArgs as Expression;
  } else {
    const result = {[operator]: processedArgs};
    if (expression.caseInsensitive !== undefined) {
      result.caseInsensitive = expression.caseInsensitive;
    }
    return result;
  }
}

function simplifyOperator(expression: Expression, noShortCiruit: boolean = false) {
  if (isConstantExpression(expression)) {
    return expression;
  }

  const {operator, args} = getOperatorAndArgs(expression);

  switch (operator) {
    case Operator.EQUAL:
      if (resultEquals(args![0], args![1], expression.caseInsensitive ?? true)) {
        return true;
      } else if (isConstantExpression(args![0]) && isConstantExpression(args![1])) {
        return false;
      }
      break;

    case Operator.NOT_EQUAL:
      if (resultEquals(args![0], args![1], expression.caseInsensitive ?? true)) {
        return false;
      } else if (isConstantExpression(args![0]) && isConstantExpression(args![1])) {
        return true;
      }
      break;

    case Operator.AND: {
      const nonConstantArgs: Expression[] = [];
      for (const arg of args as Expression[]) {
        if (isConstantExpression(arg)) {
          if (noShortCiruit) {
            nonConstantArgs.push(arg);
          } else if (!arg) {
            return arg;
          }
        } else if (Operator.AND in arg) {
          nonConstantArgs.push(...arg[Operator.AND]!);
        } else {
          nonConstantArgs.push(arg);
        }
      }
      if (nonConstantArgs.length === 0) {
        return true;
      } else if (nonConstantArgs.length === 1) {
        return nonConstantArgs[0];
      } else {
        return {[Operator.AND]: nonConstantArgs};
      }
    }

    case Operator.OR: {
      const nonConstantArgs: Expression[] = [];
      for (const arg of args as Expression[]) {
        if (isConstantExpression(arg)) {
          if (noShortCiruit) {
            nonConstantArgs.push(arg);
          } else if (arg) {
            return arg;
          }
        } else if (Operator.OR in arg) {
          nonConstantArgs.push(...arg[Operator.OR]!);
        } else {
          nonConstantArgs.push(arg);
        }
      }
      if (nonConstantArgs.length === 0) {
        return false;
      } else if (nonConstantArgs.length === 1) {
        return nonConstantArgs[0];
      } else {
        return {[Operator.OR]: nonConstantArgs};
      }
    }

    case Operator.IN:
    case Operator.NOT_IN:
      if (isConstantExpression(args![0])) {
        let nonConstant = false;
        for (const val of chain((v) => ensureArray(v), ensureArray(args![1]))) {
          if (resultEquals(args![0], val, expression.caseInsensitive)) {
            return operator === Operator.IN;
          } else if (!isConstantExpression(val)) {
            nonConstant = true;
          }
        }
        if (!nonConstant) {
          return operator !== Operator.IN;
        }
      }
      break;

    case Operator.CONDITIONAL: {
      const newArgs: Expression[] = [];
      for (let idx = 0; idx < args.length; idx += 2) {
        if (idx === args.length - 1) {
          newArgs.push(args[idx]);
        } else if (args[idx] === true) {
          newArgs.push(args[idx + 1]);
          break;
        } else if (args[idx] !== false) {
          newArgs.push(args[idx]);
          newArgs.push(args[idx + 1]);
        }
      }

      if (newArgs.length === 1) {
        return newArgs[0];
      } else if (newArgs.length === 3) {
        if (newArgs![1] === true && newArgs![2] === false) {
          return newArgs![0];
        } else if (newArgs![1] === false && newArgs![2] === true) {
          return {[Operator.NOT]: newArgs![0]};
        } else if (equals(newArgs![1], newArgs![2])) {
          return newArgs![1];
        }
      }
      if (newArgs.length !== args.length) {
        return {[Operator.CONDITIONAL]: newArgs};
      }
      break;
    }

    case Operator.SUM:
    case Operator.PRODUCT: {
      // Flatten nested sums/products
      let expanded = false;
      const newArgs: Expression[] = [];
      for (const arg of args as Expression[]) {
        if (typeof arg === "object" && arg !== null && operator in arg) {
          newArgs.push(...arg[operator]!);
          expanded = true;
        } else {
          newArgs.push(arg);
        }
      }
      if (all((arg) => isConstantArgument(arg), newArgs)) {
        return new ExpressionEvaluator({...expression, [operator]: newArgs}).evaluate();
      } else if (expanded) {
        return {...expression, [operator]: newArgs};
      }
      break;
    }

    // Never try to simplify these
    case Operator.KEY:
    case Operator.TODAY:
    case Operator.NOW:
    case Operator.GROUP_VALUE:
    case Operator.GROUP_VALUES:
    case Operator.GROUP_INSTANCE:
    case Operator.GROUP_INSTANCE_REVERSED:
    case Operator.GROUP_INSTANCES:
    case Operator.DECODE:
    case Operator.WITH_DEPENDENCIES:
    case Operator.UNDEFINED:
    case Operator.SUMSCORES:
    case Operator.AUTO_DECODE_CONCAT:
    case Operator.NO_AUTO_DECODE:
    case Operator.PLACEHOLDER:
    case Operator.DEBUG:
    case Operator.OBJECT:
    case Operator.UUID:
    case Operator.POINTER_LOOKUP:
    case Operator.USER_HAS_ROLE:
    case Operator.FIND_STRING_IN_FILE:
    case Operator.JWS_SIGN:
    case Operator.NULL:
      break;

    default: {
      if (all(isConstantArgument, ensureArray(args))) {
        return new ExpressionEvaluator(expression).evaluate();
      }
      break;
    }
  }

  return expression;
}

function isConstantArgument(arg: Expression | undefined): boolean {
  if (isConstantExpression(arg)) {
    return true;
  }
  const {operator} = getOperatorAndArgs(arg);
  return operator === Operator.UNDEFINED || operator === Operator.NULL;
}

export function partialEval(
  exp: Expression | undefined,
  answers: {[key: string]: Expression},
  noShortCircuit?: boolean,
): Expression | undefined {
  const newExp = processExpression(exp, (operator, args) => {
    if (operator === Operator.KEY && args in answers) {
      return answers[args];
    }
    return undefined;
  });
  return simplifyExpression(newExp, false, noShortCircuit);
}

export const TODAY_MAGIC_KEY = "__TODAY";
export function findChangeDates(exp: Expression, answers: JSONObject): Date[] {
  const localized = localizeExpression(exp, [Locale.EN_US]);
  const replacedDateExp = processExpression(localized, (operator) => {
    if (operator === Operator.TODAY) {
      return {[Operator.KEY]: TODAY_MAGIC_KEY};
    } else {
      return undefined;
    }
  });
  const today = todayMoment().toDate();
  const changeDates = _findChangeDates(replacedDateExp, answers, today);
  return changeDates;
}

function _findChangeDates(exp: Expression, answers: JSONObject, today: Date): Date[] {
  if (isConstantExpression(exp)) {
    return [];
  }

  const {operator, args} = getOperatorAndArgs(exp);
  switch (operator) {
    case Operator.AND:
    case Operator.OR: {
      const changeDates: Date[] = [];
      for (const arg of args) {
        const argChangeDates = _findChangeDates(arg, answers, today);
        if (argChangeDates.length > 0) {
          changeDates.push(...argChangeDates);
        } else {
          const result = new ExpressionEvaluator(arg).evaluate(answers);
          if (Boolean(result) === (operator === Operator.OR)) {
            return []; // Date-independent value that controls the overall result, so no change dates
          }
        }
      }
      return filterChangeDates(exp, answers, changeDates);
    }

    case Operator.NOT: {
      return _findChangeDates(args[0], answers, today);
    }

    case Operator.CONDITIONAL: {
      if (args.length < 3 || args.length % 2 !== 1) {
        throw new Error("Invalid expression: " + JSON.stringify(exp));
      }

      const changeDates: Date[] = [];
      let idx = 0;
      for (; idx < args.length - 1; idx += 2) {
        const conditionChangeDates = _findChangeDates(args[idx], answers, today);
        if (conditionChangeDates.length > 0) {
          changeDates.push(...conditionChangeDates);
        } else {
          const result = new ExpressionEvaluator(args[idx]).evaluate(answers);
          if (!result) {
            // Date-independent skip, so the resulting value doesn't matter
            continue;
          }
        }

        const conditionalResultChangeDates = _findChangeDates(args[idx + 1], answers, today);
        changeDates.push(...conditionalResultChangeDates);

        if (conditionChangeDates.length === 0) {
          // Date-independent hit, so the remaining conditional doesn't matter
          break;
        }
      }
      if (idx === args.length - 1) {
        const conditionalResultChangeDates = _findChangeDates(args[idx], answers, today);
        changeDates.push(...conditionalResultChangeDates);
      }
      return filterChangeDates(exp, answers, changeDates);
    }

    default: {
      const evaluator = new ExpressionEvaluator(exp);
      const changeDate = findChangeDate(evaluator, answers, today);
      return changeDate ? [changeDate] : [];
    }
  }
}

function findChangeDate(evaluator: ExpressionEvaluator, answers: JSONObject, today: Date): Date | undefined {
  let date1 = new Date(today.getTime());
  let date2 = new Date(today.getTime() + 1e12); // Roughly 30 years hence.
  const result1 = evaluator.evaluate(answers, {[TODAY_MAGIC_KEY]: date1});
  const result2 = evaluator.evaluate(answers, {[TODAY_MAGIC_KEY]: date2});
  if (result1 === result2) {
    return undefined;
  }

  // Approximately 40 iterations expected
  while (date2.getTime() > date1.getTime()) {
    const testDate = new Date(date1.getTime() + (date2.getTime() - date1.getTime()) / 2);
    const testRes = evaluator.evaluate(answers, {[TODAY_MAGIC_KEY]: formatDate(testDate)});
    if (testRes === result1) {
      date1 = new Date(testDate.getTime() + 1); // Add a millisecond to ensure we converge.
    } else {
      date2 = testDate;
    }
  }

  return parseDate(date2).toDate();
}

function filterChangeDates(replacedDateExp: Expression, answers: JSONObject, changeDates: Date[]): Date[] {
  const result: Date[] = [];
  const evaluator = new ExpressionEvaluator(replacedDateExp);
  for (const date of [...new Set(changeDates)]) {
    const before = evaluator.evaluate(answers, {[TODAY_MAGIC_KEY]: new Date(date.getTime() - MILLISECONDS_PER_DAY)});
    const after = evaluator.evaluate(answers, {[TODAY_MAGIC_KEY]: new Date(date.getTime())});
    if (before !== after) {
      result.push(date);
    }
  }
  return result;
}
export type BasicAsyncExpressionImplementation = (...args: ExpressionResult) => Promise<ExpressionResult>;
export type PointerLookup = (pointer: Pointer, exp: Expression) => Promise<ExpressionResult>;

export interface AsyncExpressionOptions
  extends Omit<ExpressionOptions, "decode" | "userHasRole" | "pointerLookup" | "fileContents"> {
  decode?: BasicAsyncExpressionImplementation;
  userHasRole?: BasicAsyncExpressionImplementation;
  pointerLookup?: PointerLookup;
  fileContents?: BasicAsyncExpressionImplementation;
  cacheResults?: boolean;
}

export class AsyncExpressionEvaluator {
  private syncEvaluator: ExpressionEvaluator;
  private promises: Array<Promise<any>> = [];
  private decodeResults: Dictionary<ExpressionResult> = {};
  private userHasRoleResults: Dictionary<ExpressionResult> = {};
  private pointerLookupResults: Dictionary<ExpressionResult> = {};
  private findStringInFileResults: Dictionary<ExpressionResult> = {};
  private fileContentsResults: Dictionary<ExpressionResult> = {};
  private cacheResults: boolean;

  public constructor(public readonly expression: Expression | Date | undefined, options?: AsyncExpressionOptions) {
    this.syncEvaluator = new ExpressionEvaluator(expression, {
      ...options,
      decode: (...args) => {
        const k = JSON.stringify(args);
        if (k in this.decodeResults) {
          return this.decodeResults[k];
        } else {
          if (options?.decode) {
            this.promises.push(options.decode(...args).then((result) => (this.decodeResults[k] = result)));
          }
          return args[0];
        }
      },
      userHasRole: (...args) => {
        const k = JSON.stringify(args);
        if (k in this.userHasRoleResults) {
          return this.userHasRoleResults[k];
        } else {
          if (options?.userHasRole) {
            this.promises.push(options?.userHasRole(...args).then((result) => (this.userHasRoleResults[k] = result)));
          }
          return false;
        }
      },
      findStringInFile: (...args) => {
        const k = JSON.stringify(args);
        if (k in this.findStringInFileResults) {
          return this.findStringInFileResults[k];
        } else {
          if (options?.findStringInFile) {
            this.promises.push(
              options.findStringInFile(...args).then((result) => (this.findStringInFileResults[k] = result)),
            );
          } else {
            throw new Error("Invalid expression, unable to run findStringInFile");
          }
          return false;
        }
      },
      pointerLookup: (pointer, exp) => {
        const k = JSON.stringify([pointer, exp]);
        if (k in this.pointerLookupResults) {
          return this.pointerLookupResults[k];
        } else {
          if (options?.pointerLookup) {
            this.promises.push(
              options.pointerLookup(pointer, exp).then((result) => (this.pointerLookupResults[k] = result)),
            );
          }
          return undefined;
        }
      },
      fileContents: (...args) => {
        const k = JSON.stringify(args);
        if (k in this.fileContentsResults) {
          const result = this.fileContentsResults[k];
          // We shouldn't cache these results due to the potential size of files
          // so they are deleted right after they are read.
          delete this.fileContentsResults[k];
          return result;
        } else {
          if (options?.fileContents) {
            this.promises.push(options.fileContents(...args).then((result) => (this.fileContentsResults[k] = result)));
          } else {
            throw new Error("Invalid expression, unable to run encodedFileContents");
          }
          return undefined;
        }
      },
    });
    this.cacheResults = !!options?.cacheResults;
  }

  public get answerKeys() {
    return this.syncEvaluator.answerKeys;
  }

  public async evaluate(...data: JSONObject[]): Promise<ExpressionResult> {
    let result: ExpressionResult;
    let maxTries = 10000;
    do {
      if (this.promises.length > 0) {
        await Promise.all(this.promises);
        this.promises = [];
      }
      result = this.syncEvaluator.evaluate(...data);
      maxTries--;
    } while (this.promises.length !== 0 && maxTries > 0);

    if (!this.cacheResults) {
      this.decodeResults = {};
      this.pointerLookupResults = {};
    }

    return result;
  }
}

enum PrecedenceClass {
  CONDITIONAL,
  OR,
  AND,
  NOT,
  COMPARISON,
  ADDITION,
  MULTIPLICATION,
}

interface OperatorData {
  functionName?: string;
  precedenceClass?: PrecedenceClass;
}

const operatorData: Record<Operator, OperatorData> = {
  [Operator.LENGTH]: {
    functionName: "Length",
  },
  [Operator.CONCAT]: {
    functionName: "Concat",
  },
  [Operator.CONCAT_ARRAY]: {
    functionName: "ConcatArray",
  },
  [Operator.INTERSECTS]: {
    functionName: "Intersects",
  },
  [Operator.APPLY_CONSTANTS]: {
    functionName: "ApplyConstants",
  },
  [Operator.ADD_DAYS]: {
    functionName: "AddDays",
  },
  [Operator.ADD_MINUTES]: {
    functionName: "AddMinutes",
  },
  [Operator.GROUP_VALUE]: {
    functionName: "GroupValue",
  },
  [Operator.GROUP_VALUES]: {
    functionName: "GroupValues",
  },
  [Operator.GROUP_INSTANCE]: {
    functionName: "GroupInstance",
  },
  [Operator.GROUP_INSTANCE_REVERSED]: {
    functionName: "GroupInstances",
  },
  [Operator.MARKDOWN_ESCAPE]: {
    functionName: "MarkdownEscape",
  },
  [Operator.REPLACE]: {
    functionName: "Replace",
  },
  [Operator.MATCH]: {
    functionName: "Match",
  },
  [Operator.JOIN]: {
    functionName: "Join",
  },
  [Operator.TO_UPPER]: {
    functionName: "ToUpper",
  },
  [Operator.TO_LOWER]: {
    functionName: "ToLower",
  },
  [Operator.URL_ENCODE]: {
    functionName: "UrlEncode",
  },
  [Operator.ANY]: {
    functionName: "ArrayAny",
  },
  [Operator.ALL]: {
    functionName: "ArrayAll",
  },
  [Operator.MAP]: {
    functionName: "ArrayAny",
  },
  [Operator.FILTER]: {
    functionName: "ArrayAll",
  },
  [Operator.UNIQUE]: {
    functionName: "ArrayUnique",
  },
  [Operator.DECODE]: {
    functionName: "Decode",
  },
  [Operator.COMPACT]: {
    functionName: "Compact",
  },
  [Operator.GROUP_INSTANCES]: {
    functionName: "GroupInstances",
  },
  [Operator.WITH_DEPENDENCIES]: {
    functionName: "WithDependencies",
  },
  [Operator.SUM]: {
    functionName: "Sum",
  },
  [Operator.PRODUCT]: {
    functionName: "Product",
  },
  [Operator.SUMSCORES]: {
    functionName: "SumScores",
  },
  [Operator.AUTO_DECODE_CONCAT]: {
    functionName: "AutoDecodeConcat",
  },
  [Operator.NO_AUTO_DECODE]: {
    functionName: "NoAutoDecode",
  },
  [Operator.LOCALIZE]: {
    functionName: "Localize",
  },
  [Operator.PLACEHOLDER]: {
    functionName: "Placeholder",
  },
  [Operator.DEBUG]: {
    functionName: "Debug",
  },
  [Operator.COALESCE]: {
    functionName: "Coalesce",
  },
  [Operator.OBJECT]: {
    functionName: "Object",
  },
  [Operator.ROUND_TO]: {
    functionName: "RoundTo",
  },
  [Operator.PICK]: {
    functionName: "Pick",
  },
  [Operator.UUID]: {
    functionName: "UUID",
  },
  [Operator.POINTER_LOOKUP]: {
    functionName: "PointerLookup",
  },
  [Operator.USER_HAS_ROLE]: {
    functionName: "UserHasRole",
  },
  [Operator.FIND_STRING_IN_FILE]: {
    functionName: "FindStringInFile",
  },
  [Operator.FILE_CONTENTS]: {
    functionName: "FileContents",
  },
  [Operator.PHONE_NUMBER_BASE]: {
    functionName: "PhoneNumberBase",
  },
  [Operator.PHONE_NUMBER_COUNTRY]: {
    functionName: "PhoneNumberCountry",
  },
  [Operator.PHONE_NUMBER_COUNTRY_CODE]: {
    functionName: "PhoneNumberCountryCode",
  },
  [Operator.JWS_SIGN]: {
    functionName: "JWSSign",
  },
  [Operator.SPLIT]: {
    functionName: "Split",
  },
  [Operator.FORMAT_DATE]: {
    functionName: "FormatDate",
  },
  [Operator.SUBSTRING]: {
    functionName: "Substring",
  },
  [Operator.UNACCENT]: {
    functionName: "Unaccent",
  },
  [Operator.KEYS]: {
    functionName: "Keys",
  },
  [Operator.EQUAL]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.NOT_EQUAL]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.GREATER_THAN]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.LESS_THAN]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.GREATER_THAN_OR_EQUAL]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.LESS_THAN_OR_EQUAL]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.OR]: {
    precedenceClass: PrecedenceClass.OR,
  },
  [Operator.AND]: {
    precedenceClass: PrecedenceClass.AND,
  },
  [Operator.IN]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.NOT_IN]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.KEY]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.NOT]: {
    precedenceClass: PrecedenceClass.COMPARISON,
  },
  [Operator.CONDITIONAL]: {
    precedenceClass: PrecedenceClass.CONDITIONAL,
  },
  [Operator.TODAY]: {},
  [Operator.NOW]: {},
  [Operator.DIFFERENCE]: {
    precedenceClass: PrecedenceClass.ADDITION,
  },
  [Operator.QUOTIENT]: {
    precedenceClass: PrecedenceClass.MULTIPLICATION,
  },
  [Operator.UNDEFINED]: {},
  [Operator.ARRAY]: {},
  [Operator.NULL]: {},
};

export class FancyExpressionLogger {
  private static functionName(operator: Operator) {
    return operatorData[operator]?.functionName ?? operator;
  }

  private static handlePrecedence(
    decompiled: string,
    operator: Operator,
    parentOperator: Operator | undefined,
  ): string {
    if (this.isLowerPrecedence(operator, parentOperator)) {
      return `(${decompiled})`;
    } else {
      return decompiled;
    }
  }

  private static isLowerPrecedence(operator: Operator, parentOperator: Operator | undefined) {
    const op1 = operatorData[operator]?.precedenceClass;
    const op2 = parentOperator && operatorData[parentOperator]?.precedenceClass;
    return op1 !== undefined && op2 !== undefined && op1 < op2;
  }

  private last: string | undefined;
  private evalMap: Map<Expression | Date | undefined, ExpressionResult> = new Map();
  private parentMap: Map<Expression | Date | undefined, Expression | Date | undefined> = new Map();
  private evaluation: string[] = [];
  constructor(private readonly expression: Expression | undefined, private readonly hideAnswers: boolean = false) {
    this.evaluation.push(
      "This is a decompiler, not a source debugger. Expressions displayed may not match original source.",
    );
    this.log();
  }

  public log = (expression?: Expression | Date | undefined, result?: ExpressionResult) => {
    this.invalidate(expression);
    this.evalMap.set(expression, result);
    const current = this.decompile(this.expression, undefined, undefined);
    if (current !== this.last) {
      this.evaluation.push(current);
    }
    this.last = current;
  };

  public getLog(): string[] {
    return [...this.evaluation];
  }

  private resultString(result: ExpressionResult, safeResult: boolean = false): string {
    if (!this.hideAnswers || typeof result === "boolean" || safeResult) {
      return result === undefined ? "undefined" : JSON.stringify(result);
    } else {
      return "XXXXX";
    }
  }

  private invalidate(expression: Expression | Date | undefined) {
    this.evalMap.delete(expression);
    if (this.parentMap.has(expression)) {
      this.invalidate(this.parentMap.get(expression));
    }
  }

  private decompile(
    expression: Expression | Date | undefined,
    parentExpression: Expression | undefined,
    parentOperator: Operator | undefined,
  ): string {
    if (parentExpression) {
      this.parentMap.set(expression, parentExpression);
    }

    if (this.evalMap.has(expression)) {
      const rs = this.resultString(this.evalMap.get(expression));
      return rs;
    }

    if (
      typeof expression === "boolean" ||
      typeof expression === "number" ||
      typeof expression === "string" ||
      typeof expression === "undefined" ||
      expression === null ||
      expression instanceof Date
    ) {
      return this.resultString(expression, true);
    }

    const {operator, args} = getOperatorAndArgs(expression);
    let decompiled: string;
    switch (operator) {
      case Operator.EQUAL:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" = ");
        break;
      case Operator.NOT_EQUAL:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" <> ");
        break;
      case Operator.GREATER_THAN:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" > ");
        break;
      case Operator.LESS_THAN:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" < ");
        break;
      case Operator.GREATER_THAN_OR_EQUAL:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" >= ");
        break;
      case Operator.LESS_THAN_OR_EQUAL:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" <= ");
        break;
      case Operator.AND:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" AND ");
        break;
      case Operator.OR:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" OR ");
        break;
      case Operator.IN:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" IN ");
        break;
      case Operator.NOT_IN:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" NOT IN ");
        break;
      case Operator.KEY:
        decompiled = `<${args}>`;
        break;
      case Operator.ARRAY:
        decompiled = `[${coerceToArray(args)
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")}]`;
        break;
      case Operator.CONDITIONAL: {
        const parts: string[] = [];
        for (let idx = 0; idx < args.length; idx++) {
          let parens = true;
          if (idx === 0) {
            parts.push("IF");
          } else if (idx === args.length - 1) {
            parts.push("ELSE");
            parens = false;
          } else if (idx % 2 === 0) {
            parts.push("ELSE IF");
          } else {
            parts.push("THEN");
            parens = false;
          }
          parts.push(
            parens
              ? `(${this.decompile(args[idx], expression, operator)})`
              : this.decompile(args[idx], expression, operator),
          );
        }
        decompiled = parts.join(" ");
        break;
      }
      case Operator.NOT:
        decompiled = `NOT ${this.decompile(args, expression, operator)}`;
        break;
      case Operator.TODAY:
        decompiled = `Today`;
        break;
      case Operator.NOW:
        decompiled = `Now`;
        break;
      case Operator.ANY:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${[...coerceToArray(args)]
          .reverse()
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;
      case Operator.ALL:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${[...coerceToArray(args)]
          .reverse()
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;
      case Operator.MAP:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${[...coerceToArray(args)]
          .reverse()
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;
      case Operator.FILTER:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${[...coerceToArray(args)]
          .reverse()
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;
      case Operator.UNIQUE:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${[...coerceToArray(args)]
          .reverse()
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;

      case Operator.SUM:
        if (args.length === 2) {
          decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" + ");
        } else {
          decompiled = `${FancyExpressionLogger.functionName(operator)}(${coerceToArray(args)
            .map((a) => this.decompile(a, expression, operator))
            .join(", ")})`;
        }
        break;
      case Operator.PRODUCT:
        if (args.length === 2) {
          decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" * ");
        } else {
          decompiled = `${FancyExpressionLogger.functionName(operator)}(${coerceToArray(args)
            .map((a) => this.decompile(a, expression, operator))
            .join(", ")})`;
        }
        break;
      case Operator.DIFFERENCE:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" - ");
        break;
      case Operator.QUOTIENT:
        decompiled = args.map((a) => this.decompile(a, expression, operator)).join(" / ");
        break;
      case Operator.OBJECT: {
        const parts: string[] = [];
        for (const k of Object.keys(args)) {
          parts.push(`"${k}":${this.decompile(args[k], expression, operator)}`);
        }
        decompiled = `{${parts.join(", ")}}`;
        break;
      }

      case Operator.UNDEFINED:
        decompiled = "undefined";
        break;

      case Operator.GROUP_INSTANCE:
      case Operator.GROUP_INSTANCE_REVERSED:
      case Operator.GROUP_INSTANCES:
      case Operator.LENGTH:
      case Operator.CONCAT:
      case Operator.CONCAT_ARRAY:
      case Operator.INTERSECTS:
      case Operator.APPLY_CONSTANTS:
      case Operator.ADD_DAYS:
      case Operator.ADD_MINUTES:
      case Operator.GROUP_VALUE:
      case Operator.GROUP_VALUES:
      case Operator.MARKDOWN_ESCAPE:
      case Operator.REPLACE:
      case Operator.MATCH:
      case Operator.JOIN:
      case Operator.TO_UPPER:
      case Operator.TO_LOWER:
      case Operator.URL_ENCODE:
      case Operator.DECODE:
      case Operator.COMPACT:
      case Operator.WITH_DEPENDENCIES:
      case Operator.SUMSCORES:
      case Operator.AUTO_DECODE_CONCAT:
      case Operator.NO_AUTO_DECODE:
      case Operator.LOCALIZE:
      case Operator.PLACEHOLDER:
      case Operator.DEBUG:
      case Operator.COALESCE:
      case Operator.ROUND_TO:
      case Operator.PICK:
      case Operator.UUID:
      case Operator.POINTER_LOOKUP:
      case Operator.USER_HAS_ROLE:
      case Operator.FIND_STRING_IN_FILE:
      case Operator.PHONE_NUMBER_BASE:
      case Operator.PHONE_NUMBER_COUNTRY:
      case Operator.PHONE_NUMBER_COUNTRY_CODE:
      case Operator.JWS_SIGN:
      case Operator.FORMAT_DATE:
      case Operator.SUBSTRING:
      case Operator.UNACCENT:
      case Operator.NULL:
      case Operator.KEYS:
      default:
        decompiled = `${FancyExpressionLogger.functionName(operator)}(${coerceToArray(args)
          .map((a) => this.decompile(a, expression, operator))
          .join(", ")})`;
        break;
    }
    return FancyExpressionLogger.handlePrecedence(decompiled, operator, parentOperator);
  }
}

export function transformForEachExpression(exp: Expression, listItem: ListItemDTO): Expression;
export function transformForEachExpression(exp: Expression | undefined, listItem: ListItemDTO): Expression | undefined;
export function transformForEachExpression(exp: Expression | undefined, listItem: ListItemDTO): Expression | undefined {
  const transformed = processExpression(exp, (operator, args) => {
    if (operator === Operator.KEY && args.startsWith(MagicAnswerKeys.FOR_EACH)) {
      const split = args.split(".");
      if (split.length === 1) {
        return listItem.value;
      } else {
        return listItem.lookup?.[split[1]] || "";
      }
    }
    return undefined;
  });
  return simplifyExpression(transformed);
}

export function transformObjectForEachExpressions(data: {[p: string]: Expression} | undefined, listItem: ListItemDTO) {
  if (data === undefined) {
    return;
  }

  for (const key of Object.keys(data)) {
    data[key] = transformForEachExpression(data[key], listItem)!;
  }
}

export function transformMaybeLocalizableForEachExpressions(
  exp: Expression | Localizable<Expression>,
  listItem: ListItemDTO,
): Expression | Localizable<Expression>;
export function transformMaybeLocalizableForEachExpressions(
  exp: Expression | Localizable<Expression> | undefined,
  listItem: ListItemDTO,
): Expression | Localizable<Expression> | undefined;
export function transformMaybeLocalizableForEachExpressions(
  exp: Expression | Localizable<Expression> | undefined,
  listItem: ListItemDTO,
): Expression | Localizable<Expression> | undefined {
  if (exp === undefined) {
    return exp;
  } else if (isLocalizable(exp)) {
    transformObjectForEachExpressions(exp, listItem);
    return exp;
  } else {
    return transformForEachExpression(exp, listItem);
  }
}
