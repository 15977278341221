export enum GroupType {
  ROOT = "root",
  TAB = "tab",
  TOPIC = "topic",
  SUBTOPIC = "subtopic",
  INTERNAL_USE_GROUP = "internal_use",
  GROUP = "group",
  GROUP_INSTANCE = "group_instance",
}

export enum GroupSubType {
  ITEM = "item",
  TABLE = "table",
  REVIEW = "review",
  TICKET = "ticket",
}

export enum QuestionType {
  IMAGE = "image",
  TEXT = "text",
  MULTI_TEXT = "multi_text",
  EMAIL = "email",
  SELECT = "select",
  RADIO = "radio",
  RADIO_OTHER = "radio_other",
  BOOLEAN = "boolean",
  CHECKBOX = "checkbox",
  CHECKBOX_OTHER = "checkbox_other",
  CHECKLIST_VIEW = "checklist_view",
  COMBO = "combobox",
  TEXTAREA = "textarea",
  FILE = "file",
  URL = "url",
  FILE_OR_URL = "file_or_url",
  TAGS = "tags",
  OBSCURED = "obscured",
  DATE = "date",
  DATE_TIME = "date_time",
  USER_SELECT = "userselect",
  DIVIDER = "divider",
  INFO = "info",
  WARNING = "warning",
  STOP = "stop",
  ADDRESS = "address",
  ROUTING = "routing",
  TWO_LIST_MULTI_SELECT = "two_list_multi_select",
  TEMPLATE = "template",
  REFERENCE_TABLE = "reference_table",
  REFERENCE_TABLE_WITHOUT_SELECTION = "reference_table_no_selection",
  COLUMN_ROW = "column_row",
  CERTIFY = "certify",
  MULTI_LANGUAGE_TEXT = "multi_language_text",
  ROLE_TABLE = "role_table",
  REFERENCE_TABLE_GIG = "reference_table_group_in_group",
  VISUAL_WORKFLOW = "visual_workflow",
  NUMBER = "number",
  BUTTON = "button",
  DOW_JONES = "dow_jones",
  KIT_ENTITY_TABLE = "kit_entity_table",
  USER_SELECT_EMAIL = "user_select_email",
  CONNECTION_SUMMARY = "connection_summary",
  POINTER = "pointer",
  CONTACT = "contact",
  KIT_TABLE = "kit_table",
  SAM_GOV = "sam_gov",
  ASSOCIATED_KIT_TABLE = "associated_kit_table",
  TOPIC_ASSIGN = "topic_assign",
  CONNECTION_OVERVIEW_BLOB = "connection_overview_blob",
}

export type ElementType = GroupType | QuestionType;
export const ElementType = {
  ...GroupType,
  ...QuestionType,
};

export enum SecurityRole {
  GRAPHITE_CSR = "graphite_csr",
  GRAPHITE_THOR = "graphite_thor",
  GRAPHITE_VALIDATOR = "graphite_validator",
  GRAPHITE_OPS = "graphite_ops",
  GRAPHITE_INTERFACES = "graphite_interfaces",
  GRAPHITE_DATA = "graphite_data",
  GRAPHITE_SUPER = "graphite_super",
  ADMIN = "admin",
  SYSTEM_ADMIN = "system_admin",
  IT_ADMIN = "it_admin",
  BANKING = "banking",
  TAX = "tax",
  INVITE_BUTTON_OWNER = "invite",
  ACCEPT_BUTTON_OWNER = "accept",
  COLLABORATE_BUTTON_OWNER = "collaborate",
  CONNECT_BUTTON_OWNER = "connect",
  CANCEL_CONNECTION = "cancel_connection",
  ACTIVE_USER = "active_user",
  EXTERNAL_USER = "external_user",
  RELAY_SERVICE = "relay_service",
  CAMPAIGNS = "campaigns",
  BULK_COMMUNICATIONS = "bulk_communications",
  RESPONDER_USER = "responder_user",
  RESPONDER_REPORT_USER = "responder_report_user",
  NPS_REPORT_USER = "NPS_REPORT_USER",
  SUPPORT = "support",
  ZENDESK = "zendesk",
  BULK_SUPPLIER_UPDATES = "bulk_supplier_updates",
  TRIGGER = "trigger",
}

export enum AccessType {
  VIEW = "view", // View the question, and the presence/absence of an answer
  READ = "read", // Read the answer
  WRITE = "write", // Write the answer
  WRITE_SCORE = "write_score", // Write the manual score associated with an answer
  DELETE = "delete", // Delete the question
  DELETE_ROW = "delete_row", // Delete rows in table
}

export enum SearchColumnType {
  // Magical types
  ENTITY = "entity",
  ENTITIES = "entities",
  ERP = "erp",
  RISK = "risk",
  CONNECTION = "connection",
  LOCATION = "location",
  ADDRESS = "address",
  TASKS = "tasks",
  CHECKBOX = "checkbox",

  // Kinda-special types
  URL = "url",
  DATE = "date",
  DATE_TIME = "date_time",
  OWNER = "owner",

  // Generic types
  ANSWER = "answer",
}

export enum TableColumnType {
  TEXT = "text",
  CHECKBOX = "checkbox",
  ADDRESS = "address",
  MAP = "map",
  LOGO = "logo",
  LOOKUP = "lookup",
  FILE = "file",
  DATE = "date",
  DATE_TIME = "date_time",
  EMAIL = "email",
  TWO_LIST_MULTI_SELECT = "multi",
  USER_SELECT = "user-select",
}

export enum RiskColumnType {
  // Magical types
  TOPIC_OR_PRODUCT = "topic_or_product",

  // Kinda-special types
  DATE = "date",
  USERSELECT = "userselect",
  RISK = "risk",

  // Generic types
  ANSWER = "answer",
}

export enum HardKitType {
  TASK = "task",
  ALL = "all",
}

// @ts-ignore -- duplicate identifier?
export type KitType = string;
